import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faSearch,
    faSignOut,
    faBullhorn,
    faEye,
    faStore,
    faShoppingCart,
    faWallet,
    faCog,
    faUserFriends,
    faCreditCardBlank,
    faIdCard,
    faTimes,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faBars,
    faQuestionCircle as regQuestionCircle,
    faHeart as regHeart,
    faCheck,
    faUniversity,
    faHandHoldingUsd,
    faEngineWarning,
} from "@fortawesome/pro-regular-svg-icons";

import { faQuestionCircle, faHeart, faShoppingBag, faBagsShopping, faTruck, faHandHoldingBox, faCreditCardFront, faBarcodeAlt } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt, faInfo } from "@fortawesome/pro-solid-svg-icons";

library.add(
    faSearch,
    faSignOut,
    faBullhorn,
    faQuestionCircle,
    regQuestionCircle,
    faEye,
    faHeart,
    regHeart,
    faStore,
    faShoppingCart,
    faWallet,
    faCog,
    faUserFriends,
    faCreditCardBlank,
    faCreditCardFront,
    faIdCard,
    faTimes,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faBars,
    faShoppingBag,
    faBagsShopping,
    faMapMarkerAlt,
    faCheck,
    faUniversity,
    faHandHoldingUsd,
    faEngineWarning,
    faTruck,
    faHandHoldingBox,
    faInfo,
    faBarcodeAlt
)
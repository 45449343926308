import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop'
import TagManager from 'react-gtm-module'
import { PathFinder } from './PathFinder';
import { connect } from 'react-redux';

// Views
//import Home from './common_pages/Home';
import Product from './common_pages/Product';
import BuyPoints from './common_pages/BuyPoints';
import Store from './common_pages/Store';
import Help from './common_pages/Help.jsx';
import MobileSearch from './common_pages/MobileSearch';
import Results from './common_pages/Results';
import TermsConditions from './common_pages/TermsConditions';
import PrivacyPolicies from './common_pages/PrivacyPolicies.jsx';
import Partners from './common_pages/partners/Partners';
import PromotionPage from './common_pages/partners/PartnerPage';
import Promotions from './common_pages/Promotions';
import HowItWorks from './common_pages/HowItWorks';
import Welcome from './common_pages/auth/Welcome';
import Login from './common_pages/auth/Login';
import CreateAccount from './common_pages/auth/CreateAccount';
import RecoverPassword from './common_pages/auth/RecoverPassword';
import CreateAd from './common_pages/CreateAd';
import ReviewProduct from './common_pages/ReviewProduct';
import CompleteRegistration from './common_pages/CompleteRegistration';
import MyAds from './common_pages/account/MyAds';
import MyFavorites from './common_pages/account/MyFavorites';
import MyPurchases from './common_pages/account/MyPurchases';
import MySales from './common_pages/account/MySales';
import MyProfile from './common_pages/account/profile/MyProfile';
import Messenger from './common_pages/account/Messenger/Messages';
import MyWallet from './common_pages/account/MyWallet';
import PaymentMethods from './common_pages/account/PaymentMethods';
import MyAddress from './common_pages/account/MyAddress';
import ShoppingCart from './common_pages/account/ShoppingCart';
import AddPaymentMethod from './common_pages/account/AddPaymentMethod';
import InviteFriends from './common_pages/account/InviteFriends';
import ActivityHistoryPage from './common_pages/account/ActivityHistoryPage';
import NotFound404 from './common_pages/NotFound404';

// * Thanks Pages
import CheckoutThanks from "./common_pages/thanks-pages/checkout";
import CheckoutThanksBrands from "./common_pages/thanks-pages/checkout-brands";
import CreateAccountThanks from "./common_pages/thanks-pages/create-account";
import BankTransferThanks from "./common_pages/thanks-pages/bank-transfer";

// Institucional
import Institutional from "./institutional/"

// Landing Page
import Landing from './common_pages/landing';

// Googe analytics
import ReactGA from 'react-ga';
import config from './configs/config.json';

import LayoutRoute from './utils/LayoutRoute';
import PrivateRoute from './utils/PrivateRoute';

let tagManagerArgs = {
    gtmId: config.gtmID,
    auth: "",
    preview: "",
    dataLayer: {
        event: 'PageView'
    }
}


const listOfCommonPages = [
    //"",
    "produto",
    "ajuda",
    "buscar",
    "resultados",
    "termos-e-condicoes",
    "politica-de-privacidade",
    "parceiros",
    "desconto",
    "como-funciona",
    "prosseguir",
    "login",
    "criar-conta",
    "recuperar-senha",
    "sobre-o-scamb",
    "in",
    "minha-sacola",
    "promocoes",
    "comprar-pontos",
    "criar-novo",
    "editar-anuncio",
    "revisar-anuncio",
    "completar-cadastro",
    "meus-anuncios",
    "minhas-vendas",
    "meus-favoritos",
    "minhas-compras",
    "meu-perfil",
    "mensagens",
    "minha-carteira",
    "metodos-de-pagamento",
    "metodo-de-pagamento",
    "meus-enderecos",
    "convidar-amigos",
    "historico-de-atividades",
    "loja",
    "pedido-sucesso",
    "pedido-sucesso-marcas",
    "cadastro-realizado",
    "transferencia",
    "landing"
]

class Routes extends Component {

    constructor(props) {
        super(props);

        // Tag Manager
        tagManagerArgs.dataLayer.pagePath = window.location.pathname + window.location.search;
        TagManager.dataLayer(tagManagerArgs);

        // Analytics
        ReactGA.initialize(config.ga);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.myUrls = [];

        props.history.listen(() => {
            ReactGA.pageview(window.location.pathname + window.location.search);

            let pathname = this.props.history.location.pathname;
            if (pathname !== '/produto' && pathname !== '/resultados' && pathname.indexOf('/loja') === -1) {
                localStorage.removeItem('scrollYS');
                localStorage.removeItem('scrollY');
            }

            tagManagerArgs.dataLayer.pagePath = window.location.pathname + window.location.search;
            TagManager.dataLayer(tagManagerArgs);

            if (this.myUrls.length > 1) {
                this.myUrls.shift();
                this.setLastURLVisited();
            } else {
                this.setLastURLVisited();
            }
        });
    }

    setLastURLVisited = () => {
        this.myUrls.push({ name: window.location.pathname, url: window.location.pathname + window.location.search });
        sessionStorage.setItem('recentlyUrl', JSON.stringify(this.myUrls));
    }

    render() {
        if (listOfCommonPages.indexOf(window.location.pathname.split('/')[1].replace(new RegExp('/', 'g'), '')) === -1) {

            try {
                let SiteRoutes = require(`./${this.props.website}/Routes`);
                return (
                    <ScrollToTop>
                        <SiteRoutes.default />
                    </ScrollToTop>
                );

            } catch (e) {
                return (<NotFound404 />)
            }

        } else {
            let layout = require(`./${this.props.website}/Layout`).default;

            return (
                <ScrollToTop>
                    <Switch>

                        {/* Páginas deslogadas */}
                        {/*<Route exact path="/" component={Home} />*/}
                        <LayoutRoute layout={layout} exact path="/produto" component={Product} />
                        <LayoutRoute layout={layout} exact path="/ajuda" component={Help} />
                        <LayoutRoute layout={layout} exact path="/buscar" component={MobileSearch} />
                        <LayoutRoute layout={layout} exact path="/resultados" component={Results} />
                        <LayoutRoute layout={layout} exact path="/termos-e-condicoes" component={TermsConditions} />
                        <LayoutRoute layout={layout} exact path="/politica-de-privacidade" component={PrivacyPolicies} />
                        <LayoutRoute layout={layout} exact path="/parceiros" component={Partners} />
                        <LayoutRoute layout={layout} exact path="/desconto" component={PromotionPage} />
                        <LayoutRoute layout={layout} exact path="/como-funciona" component={HowItWorks} />
                        <LayoutRoute layout={layout} exact path="/prosseguir" component={Welcome} />
                        <LayoutRoute layout={layout} exact path="/login" component={Login} />
                        <LayoutRoute layout={layout} exact path="/criar-conta" component={CreateAccount} />
                        <LayoutRoute layout={layout} exact path="/recuperar-senha" component={RecoverPassword} />
                        <LayoutRoute layout={layout} exact path="/sobre-o-scamb" component={Institutional} />
                        <LayoutRoute layout={layout} exact path="/in/:inviteCode" component={CreateAccount} />
                        <LayoutRoute layout={layout} exact path="/minha-sacola" component={ShoppingCart} />
                        <LayoutRoute layout={layout} exact path="/promocoes" component={Promotions} />
                        <Route exact path="/landing" component={Landing} />


                        {/* Páginas logadas */}
                        <PrivateRoute layout={layout} exact path="/comprar-pontos" component={BuyPoints} />
                        <PrivateRoute layout={layout} exact path="/criar-novo" component={CreateAd} />
                        <PrivateRoute layout={layout} exact path="/editar-anuncio/:idAnuncio" component={CreateAd} />
                        {/*<PrivateRoulayout={layout} te exact path="/revisar-anuncio" component={ReviewProduct} />*/}
                        <PrivateRoute layout={layout} exact path="/completar-cadastro" component={CompleteRegistration} />
                        <PrivateRoute layout={layout} exact path="/meus-anuncios" component={MyAds} />
                        <PrivateRoute layout={layout} exact path="/minhas-vendas" component={MySales} />
                        <PrivateRoute layout={layout} exact path="/meus-favoritos" component={MyFavorites} />
                        <PrivateRoute layout={layout} exact path="/minhas-compras" component={MyPurchases} />
                        <PrivateRoute layout={layout} exact path="/meu-perfil" component={MyProfile} />
                        <PrivateRoute layout={layout} exact path="/mensagens" component={Messenger} />
                        <PrivateRoute layout={layout} exact path="/minha-carteira" component={MyWallet} />
                        <PrivateRoute layout={layout} exact path="/metodos-de-pagamento" component={PaymentMethods} />
                        <PrivateRoute layout={layout} exact path="/metodo-de-pagamento" component={AddPaymentMethod} />
                        <PrivateRoute layout={layout} exact path="/meus-enderecos" component={MyAddress} />
                        <PrivateRoute layout={layout} exact path="/convidar-amigos" component={InviteFriends} />
                        <PrivateRoute layout={layout} exact path="/historico-de-atividades" component={ActivityHistoryPage} />
                        <PrivateRoute layout={layout} exact path="/pedido-sucesso" component={CheckoutThanks} />
                        <PrivateRoute layout={layout} exact path="/pedido-sucesso-marcas" component={CheckoutThanksBrands} />
                        <PrivateRoute layout={layout} exact path="/cadastro-realizado" component={CreateAccountThanks} />
                        <PrivateRoute layout={layout} exact path="/transferencia" component={BankTransferThanks} />

                        {/* Logado e Deslogado */}
                        <LayoutRoute layout={layout} exact path="/loja/:storeUrl" component={Store} />

                        <Route path="*">
                            <NotFound404 />
                        </Route>

                    </Switch>
                </ScrollToTop>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        website: state.websiteReducer.website,
        site_properties: state.websiteReducer.site_properties
    };
}

export default withRouter(connect(mapStateToProps, {})(Routes));
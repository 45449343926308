
//=====================================================================================
// #1 - Import * Banners Images
//=====================================================================================

// Banner #1 Mob & Desk
import BannerAte75Pontos from "../../../../../assets/images/banners/setembro/ate-75-pontos.jpg";
import BannerAte75PontosMobile from "../../../../../assets/images/banners/setembro/ate-75-pontos-mobile.jpg";

// Banner #1 Mob & Desk
import BannerChegouAgora from "../../../../../assets/images/banners/setembro/chegou-agora.jpg";
import BannerChegouAgoraMobile from "../../../../../assets/images/banners/setembro/chegou-agora-mobile.jpg";

// Banner #1 Mob & Desk
import PersonsFrete990 from "../../../../../assets/images/banners/setembro/frete-9-90.jpg";
import PersonsFrete990Mobile from "../../../../../assets/images/banners/setembro/frete-9-90-mobile.jpg";

// Banner #1 Mob & Desk
import PersonsMarcasQueCirculam from "../../../../../assets/images/banners/setembro/marcas-que-circulam.jpg";
import PersonsMarcasQueCirculamMobile from "../../../../../assets/images/banners/setembro/marcas-que-circulam-mobile.jpg";

// Banner #1 Mob & Desk
import PersonsMelody from "../../../../../assets/images/banners/setembro/melody.jpg";
import PersonsMelodyMobile from "../../../../../assets/images/banners/setembro/melody-mobile.jpg";

const generateRandomNumber = () => Math.floor(Math.random() * 100) + 1;

const redirectWebSite = (websiteToGo) => {

  const url = window.location.href;
  const urlToGO = websiteToGo === 'marcas' ? 'marcas.' : '';

  if (url.indexOf('localhost') >= 0) {
      localStorage.setItem('website', websiteToGo);
      window.location.href = `http://localhost:3000`;
      return false;
  } else if (url.indexOf('d1') >= 0) {
      window.location.href = `https://${urlToGO}d1.scamb.com.vc`;
  } else if (url.indexOf('h1') >= 0) {
      window.location.href = `https://${urlToGO}h1.scamb.com.vc`;
  } else {
      window.location.href = `https://${urlToGO}scamb.com.vc`;
  }

}

//=====================================================================================
// #2 - Banners Json
//=====================================================================================
export const banners = [
  {
    image_desk: BannerChegouAgora,
    image_mob: BannerChegouAgoraMobile,
    link: `/sobre-o-scamb`,
  },
  {
    image_desk: PersonsFrete990,
    image_mob: PersonsFrete990Mobile,
    link: "/promocoes",
  },
  {
    image_desk: BannerAte75Pontos,
    image_mob: BannerAte75PontosMobile,
    link: "/resultados/?pageNumber=1&showcaseId=611592aa2d4e104df6fb1fbc&aleatory=89",
  },
  {
    image_desk: PersonsMelody,
    image_mob: PersonsMelodyMobile,
    link: "/loja/melzinharepete/#/",
  },
  {
    image_desk: PersonsMarcasQueCirculam,
    image_mob: PersonsMarcasQueCirculamMobile,
    link: "#!a",
    onClickEvent: () => redirectWebSite('marcas')
  }
];
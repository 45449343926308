import config from '../configs/config.json';
import axios from 'axios';
import Swal from 'sweetalert';

import errorMessage from './../utils/ErrorMessage';

import Cookie from './../utils/Cookie';

class Service {

    constructor(){
        this.server = config.nodejs.server;
    
        this.axiosInstance = axios.create({
          withCredentials: true,
          baseURL: config.nodejs.server
        })

        // Add a response interceptor
        this.axiosInstance.interceptors.response.use(function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          // Do something with response data
          return response;
        }, function (error) {
          
          let context = this;
          if(error.response && error.response.status === 401 && window.location.pathname !== "/login"){
            

            errorMessage('', 'CMK104-SESS', undefined, 'Sessão expirada, logue novamente para continuar', () => {
              window.location.href = "/login"
              Cookie.setCookie('token', "", -1);
              
              context.setState({ isAuthenticated: false })
            });
          
 
          }

          return Promise.reject(error);
        });
    }

    loadImage(imageCode) {
      return `${config.nodejs.server}/images/${imageCode}`;
    }

    loadImageFile(imageCode) {
      return (fetch(this.loadImage(imageCode))
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], imageCode + '.webp',{type:"image/webp"});})
      );
    }

    formatErrorMessage(response, actionCode, defaultErrorMessage) {
      
      if(response){
        if(response.data?.payload) {
          if(response.data.payload.errors) {
            
            const errors = response.data.payload.errors;
            let errorsFormatted = errors.reduce((reducer, element, index) => {
              return reducer += `<li>${element.msg}</li>${errors.length == (index)? '</ul>':'' }`;
            }, "Você precisa corrigir algumas informações: <ul>");

            errorMessage('', response.data.errorCode? response.data.errorCode:'CMKT-' + actionCode, errorsFormatted, defaultErrorMessage)
          } else {
            if(response.status !== 401) {
              errorMessage('', response.data.errorCode? response.data.errorCode:'CMKT-' + actionCode, response.data.message, defaultErrorMessage)
            }
          }
        } else {

          if(response.status !== 401) {
            if(response.data.type) {
              errorMessage('', response.data.errorCode? response.data.errorCode: 'CMKT-' + actionCode, response.data.message, defaultErrorMessage)
            } else {
              this._showCommonErrorMessage(response, actionCode)
            }
          }
             
        }
      }else{
        errorMessage('', `CMKX-${actionCode}`, 'Falha ao executar a ação desejada. Entre em contato com o suporte')
      }
     
    } 

    _showCommonErrorMessage(response, actionCode) {

      if(response.status === 404) {
        errorMessage('', `CMK044-${actionCode}`, 'Ação solicitada não se encontra disponivel. Entre em contato com o suporte')
      } else if(response.status === 400) {
        errorMessage('', `CMK004-${actionCode}`, 'Falha ao executar a ação desejada. Entre em contato com o suporte')
      } else if (response.status >= 500) {
        errorMessage('', `CMK005-${actionCode}`, 'Falha ao executar a ação desejada. Entre em contato com o suporte')
      }

    }

}


export default Service;
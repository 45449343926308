import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../components/account/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';

import { faCog, faPlus, faCreditCard } from "@fortawesome/pro-light-svg-icons";

import Scamber from '../../../services/Scamber';
import StoreForm from './StoreForm';
import PersonalForm from './PersonalForm';
import AddressForm from './AddressForm';

import { setScamber } from './../../../redux/actions/scamberActions';
import Helmet from 'react-helmet';
import { CurrentPage } from "../../../components/ui/current-page";

class MyProfile extends Component {

  loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    const scamber = new Scamber();
    scamber.findScamber().then(( scamberData ) =>{

      Swal.close();
      if( scamberData.status === 200) this.props.setScamber(scamberData.data.payload);

    }).catch((error)=> Swal.fire({type:'info', html: error.response.data.message, title:''}));
  }

  render() {
    return (
      <Layout className="_profile-width">
        <div id="my-profile" className="_content">
          <CurrentPage icon={<FontAwesomeIcon icon={faCog} />} title="Meu Perfil" />
          <Helmet title={`${this.props.site_properties?.title} - Meu perfil`} />

          <StoreForm store={this.props.scamber?.minha_loja} cnpj={this.props.scamber?.cnpj ? true : false} reloadScamber={this.loadScamberData} title="Minha Loja"></StoreForm>
          <PersonalForm profile={this.props.scamber} reloadScamber={this.loadScamberData} title="Dados Pessoais"></PersonalForm>
          {/* <AddressForm address={this.props.scamber?.endereco} reloadScamber={this.loadScamberData} title="Endereço"></AddressForm> */}

        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    site_properties: state.websiteReducer.site_properties
  }
}

export default connect(mapStateToProps, { setScamber })(MyProfile);

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Layout from "../../components/account/Layout";
import Swal from 'sweetalert2';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faHeart, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { connect } from 'react-redux';

import EventEmitter from "../../services/Events";

// Import Favorites List
import ListItem from "../../components/FavoritesList";

import Cart from "../../services/Cart";

// Services
import Favorito from '../../services/Favorito';
import Scamber from '../../services/Scamber';

import Helmet from 'react-helmet';
import { CurrentPage } from "../../components/ui/current-page";


class MyFavorites extends Component {

  constructor() {
    super();

    // * Services
    this.scamber = new Scamber();
    this.favorito = new Favorito();
    this.cart = new Cart();

    this.state = {
      'favoritos': [],
      limit: 5,
      checkItem: false,
      itensCart: []
    }

    this.limit = 5;
  }

  componentDidMount() {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    this.loadFavorites(0);
  }

  loadFavorites = (sumLimit) => {
    this.limit += sumLimit;
    Swal.fire('Aguarde...')
    Swal.showLoading();
    this.favorito.list(this.limit, this.props.website)
      .then((response) => { this.setState({ 'favoritos': response.data?.payload, limit: this.limit }); Swal.close() })
      .catch((error) => this.favorito.formatErrorMessage(error.response, 'MFLIFA'));
  }

  // * Open Dialog Shopping Cart
  saveProductToCart = (ad) => {
    // if(!this.state.checkItem) {
    // }
    
    this.setState({checkItem: !this.state.checkItem})
    this.insertProductIntoCart(ad);

  };
  
  insertProductIntoCart = async (ad) => {

    EventEmitter.dispatch('startItemCart');

    let scamberAd = typeof (ad.scamber) === 'string' ? ad.scamber : ad.scamber[0]._id;
    if (this.props.cart && (this.props.cart.scamber_vendedor._id !== scamberAd)) {

      return Swal.fire({
        title: 'Você já tem itens adicionados a sua sacola que são de outro vendedor. Porém, apenas é possível finalizar compra de produtos de um único vendedor por vez.',
        icon: 'info',
        html:
          'Deseja limpar a sacola e inserir este novo item?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'Sim!',
        cancelButtonText:
          'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(ad)
          this.insertProduct(ad);
        }
      })

    } else {
      this.insertProduct(ad);
    }

  }

  insertProduct = (ad) => {
    let requestObj = {
      ad: ad._id
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    this.cart.insertCart(requestObj, ad.tipo_anuncio === 'shopping'? 'shopping': 'scamb')
      .then((response) => {
        //callback()
        Toast.fire({
          icon: 'success',
          title: 'Novos itens adicionados à sacola'
        })

        EventEmitter.dispatch('cartChange');
        EventEmitter.dispatch('endItemCart');

        this.cart.changeStatusAdsOfCart({ status: 'bloqueado' }, ad.tipo_anuncio === 'shopping'? 'shopping': 'scamb')
          .then((response) => {

            if (!this.props.scamber) {
              this.props.history.push({ pathname: '/login', state: { cart: true } })
            }

            this.checkWhereRedirect('/comprar', ad.tipo_anuncio );

          })
          .catch((error) => {
            this.checkWhereRedirect('/comprar', ad.tipo_anuncio);
            //this.cart.formatErrorMessage(error.response, 'PCHSTADC');
          });
      })
      .catch((error) => {
        this.checkWhereRedirect('/comprar', ad.tipo_anuncio);
        //this.cart.formatErrorMessage(error.response, 'PINCA');
        EventEmitter.dispatch('endItemCart');
      })
  }

  // Delete Cart Item
  deleteCartItem = (itemASerRemovido) => {

    Swal.fire({
      title: "Remover produto do carrinho?",
      icon: "warning",
      buttons: [
        'Fechar',
        'Confirmar'
      ],
      dangerMode: true
    })
      .then((isConfirm) => {
        if (isConfirm) {
          this.cart.deleteCart(itemASerRemovido, null, this.props.website)
            .then((result) => {
              Swal.fire({ title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true });
              EventEmitter.dispatch('cartChange');
            })
            .catch((error) => {
              this.favorite.formatErrorMessage(error.response, 'PDELCA')
            })
        }
      });
  }

  checkWhereRedirect = (link, type) => {
    type = type === 'shopping'? type: 'scamb';

    if(this.props.website === type) {
      this.props.history.push(link);
    } else {
      this.redirectWebSite(this.props.website === 'scamb' ? 'marcas' : 'scamb', link);
    }
  }

  redirectWebSite = (websiteToGo, link) => {
      const url = window.location.href;
      const urlToGO = websiteToGo === 'marcas' ? 'marcas.' : '';

      if (url.indexOf('localhost') >= 0) {
          localStorage.setItem('website', websiteToGo);
          window.location.href = `http://localhost:3000${link}`;
          return false;
      } else if (url.indexOf('d1') >= 0) {
          window.location.href = `https://${urlToGO}d1.scamb.com.vc${link}`;
      } else if (url.indexOf('h1') >= 0) {
          window.location.href = `https://${urlToGO}h1.scamb.com.vc${link}`;
      } else {
          window.location.href = `https://${urlToGO}scamb.com.vc${link}`;
      }

  }

  render() {

    return (
      <div id="view_my-favorites">
        <Helmet title={`${this.props.site_properties?.title} - Meus Favoritos`} />
        <Layout>
          <div id="favorites" className="_content">
            <CurrentPage icon={<FontAwesomeIcon icon={faHeart} />} title="Meus Favoritos" />
            <div className="_wrap-favorites">
              <div className="_favorites-wrap-list">

                {
                  this.state.favoritos?.map((favorito) => {
                    return (
                      favorito.anuncio[0]?.ativo ?
                        <nav className="_favorites-list">
                          <ul>
                            <ListItem
                              origin={favorito.anuncio[0]?.tipo_anuncio ? favorito.anuncio[0]?.tipo_anuncio : "scamb" }
                              sold={favorito.anuncio[0]?.dados_compra ? true : false}
                              refresh={() => this.loadFavorites(5)}
                              idFavorite={favorito._id}
                              link={`/produto?id=${favorito.anuncio[0]?._id}`}
                              image={this.favorito.loadImage(favorito.anuncio[0].fotos[0])}
                              title={favorito.anuncio[0].titulo}
                              price={favorito.anuncio[0].pontos_por?.$numberDecimal}
                              oldPrice={favorito.anuncio[0].pontos_de?.$numberDecimal}
                              clickFavorite={() => this.saveProductToCart(favorito.anuncio[0])}
                              excludeFavorite={() => this.deleteCartItem(favorito.anuncio[0]?._id, null, this.props.website)}
                              checkFavoriteCart={this?.props.cart && this?.props.cart.itens.find((item) => item._id === favorito.anuncio[0]?._id)}
                              type={favorito.anuncio[0].tipo_anuncio}
                              cashback={favorito.anuncio[0].cashback}
                              cashDiscount={favorito.anuncio[0].valor_de}
                              cashPrice={favorito.anuncio[0].valor_por} 
                            >

                            </ListItem>
                          </ul>
                        </nav>
                        : <></>
                    )
                  })
                }

              </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }} className="_wrap-load-more">
              {this.state.favoritos && this.state.favoritos.length >= this.state.limit ? (<button onClick={() => this.loadFavorites(5)} type="submit" className="btn btn-red">Carregar mais</button>) : <></>}
            </div>

          </div>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    cart: state.cartReducer.cart,
    site_properties: state.websiteReducer.site_properties,
    website: state.websiteReducer.website
  }
}

export default withRouter(connect(mapStateToProps, {})(MyFavorites));
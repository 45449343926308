//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

//===============================================================================================================
// #2 - Import * Utils
//===============================================================================================================
import maxText from "../utils/MaxText";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { CurrentPage } from "../components/ui/current-page";

//===============================================================================================================
// #6 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faChevronDown, faCalendarStar, faCalendarTimes, faChevronUp } from "@fortawesome/pro-light-svg-icons";

import BannerScamber from '../assets/images/banners/news/banner-seja-um-scamber_desktop.png';
import BannerCadastreProdutos from '../assets/images/banners/news/banner-cadastre-produtos_desktop.png';
import BannerPrimeiraCompra from '../assets/images/banners/news/primeira_compra_desktop.png';

// * Pessoas
import BannerPromoLancamento from '../assets/images/banners/persons/pessoas_00.jpg';

import BannerEntregaEm24Hrs from '../assets/images/banners/promotions/banner_entrega_em_24hrs.jpg';
import BannerFrete990 from '../assets/images/banners/setembro/frete-9-90.jpg';

import BannerPontosEmDobro from '../assets/images/banners/persons/banner_novo_01.jpg';

const Date = ({ title, date, icon }) => {

    return (
        <div className="date-box">

            <div className="_collums">
                <div className="_collum"> {title} </div>
                <div className="_collum">
                    <div className="icon">
                        {icon}
                    </div>
                    <div className="field">
                        {date}
                    </div>
                </div>
            </div>
        </div>
    )
}

const PromotionBox = ({ banner, title, subtitle, dateStart, dateEnd, children }) => {

    // * States
    const [panel, setPanel] = React.useState(false);

    return (
        <div className="promotion-box">
            <div className="promotion" onClick={() => setPanel(!panel)}>
                <div className="_cols">
                    <div className="_col">
                        <div className="banner">
                            <img src={banner} alt="banner promoções" />
                        </div>
                    </div>

                    <div className="_col">
                        <div className="info">
                            <hgroup>
                                <h1> {title} </h1>
                                <h2> {subtitle} </h2>
                            </hgroup>

                            <div className="dates">
                                <h1>Período:</h1>
                                <div className="wrap">
                                    {dateStart}
                                    {dateEnd}
                                </div>
                            </div>
                        </div>

                        <div className="icon">
                            <button type="button" className="btn btn-primary">
                                <Icon icon={panel ? faChevronUp : faChevronDown} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {panel && (
                <div className="promotion-content">
                    <h1 className="title">Detalhes</h1>

                    {children}
                </div>
            )}
        </div>
    )
}

function Promotions(props) {

    const site = props.website;

    return (
        <section id="view_promotions">
            <Helmet title={`${props.site_properties?.title} - Promoções`} />

            <div className="_wrap-current">
                <div className="_med-container">
                    <CurrentPage icon={<Icon icon={faBullhorn} />} title="Promoções" />
                </div>
            </div>

            {site === "scamb" && (
                <div className="_content _med-container">

                    <PromotionBox
                        banner={BannerFrete990}
                        title="Frete R$9,90 para todo o Brasil"
                        subtitle={`Promo frete R$9,90 para todo o Brasil até 0,5kg`}
                        dateStart={<Date title="Início" date="12/08/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Compre qualquer produto dentro do Scamb até 0,5kg e pague R$9,90 de frete  para todo o Brasil.
                            </p>

                            <p>
                                A promoção é válida  tanto para a área "pessoas que circulam" como para a área "marcas que circulam".
                            </p>

                            <p>
                                Condição válida para produtos vendidos e enviados pelo Scamb com a opção "correios".
                            </p>

                            <p>
                                O presente Regulamento poderá ser alterado e/ou a Promoção suspensa e/ou cancelada a qualquer momento, sem prévio aviso.
                            </p>

                            <p>
                                <small>Promoção válida até às 23h59 do dia 20.10.21</small>
                            </p>
                        </div>
                    </PromotionBox>

                    <PromotionBox
                        banner={BannerEntregaEm24Hrs}
                        title="Entrega em 24h SP Capital para SP Capital"
                        subtitle={`Seleção Scamb com entrega em até 24h de Região Metropolitana de São Paulo`}
                        dateStart={<Date title="Início" date="01/08/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Compre qualquer produto da seleção "SP Capital para SP Capital"  na página inicial do Scamb e receba em até 24hs.
                            </p>

                            <p>
                                A promoção é válida  para todos os produtos na seleção "SP Capital para SP Capital"   na área "pessoas que circulam"
                            </p>

                            <p>
                                Condição válida para produtos vendidos e enviados pelo Scamb com a opção entrega por correios,  não inclui a opção entrega em mãos.
                            </p>

                            <p>
                                A promoção é válida  de segunda a sábado, exceto domingos e feriados.
                            </p>
                            <p>
                                O presente Regulamento poderá ser alterado e/ou a Promoção suspensa e/ou cancelada a qualquer momento, sem prévio aviso.
                            </p>
                            <p>
                                <small>Promoção válida até às 23h59 do dia 30.08.21</small>
                            </p>
                        </div>
                    </PromotionBox>

                    <PromotionBox
                        banner={BannerScamber}
                        title="Seja um Scamber"
                        subtitle="Cadastre-se na plataforma e ganhe 25 pontos! "
                        dateStart={<Date title="Início" date="01/08/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Cadastre-se na plataforma preenchendo todos os requisitos necessários para um registro completo (nome, email, CPF, telefone e endereço).
                                Após a confirmação do cadastro completo, você receberá na sua conta Scamb 25 pontos.
                            </p>

                            <p>
                                Os pontos bonificados têm a validade de 4 meses a partir da data do recebimento dos pontos na carteira.
                            </p>

                            <p>
                                Cada CPF pode ter apenas uma conta no Scamb. O mesmo vale para a conta de email. Cada email representa uma conta no Scamb.
                            </p>
                        </div>
                    </PromotionBox>

                    <PromotionBox
                        banner={BannerCadastreProdutos}
                        title="Cadastre produtos e ganhe pontos!"
                        subtitle="Ganhe 5 pontos a cada produto publicado na plataforma."
                        dateStart={<Date title="Início" date="01/08/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Cadastre seus produtos na plataforma e ganhe 5 pontos por produto publicado.
                                Promoção válida para os 10 primeiros produtos que você cadastrar.
                                Não é necessário cadastrar todos os produtos de uma vez.
                            </p>

                            <p>
                                Os pontos bonificados têm a validade de 4 meses a partir da data do recebimento dos pontos na carteira.
                            </p>

                            <p>
                                Para receber os pontos em sua carteira, os produtos precisam ser publicados, ou seja, passaram pela curadoria Scamb após o cadastro realizado.
                            </p>
                        </div>
                    </PromotionBox>

                    <PromotionBox
                        banner={BannerPrimeiraCompra}
                        title="Faça a primeira compra e ganhe pontos!"
                        subtitle="Realize sua primeira compra no Scamb e ganhe 25 pontos!"
                        dateStart={<Date title="Início" date="08/03/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Realize sua primeira compra no Scamb. Com a confirmação do pagamento e da finalização da transação (produto recebido e aceito), você recebe 25 pontos na sua conta Scamb.
                            </p>

                            <p>
                                Os pontos bonificados têm a validade de 4 meses a partir da data do recebimento dos pontos na carteira.
                            </p>

                            <p>
                                Em caso de cancelamento ou devolução da compra por qualquer motivo, os pontos não serão creditados em sua conta.
                            </p>
                        </div>
                    </PromotionBox>

                </div>
            )}

            {site === "shopping" && (
                <div className="_content _med-container">

                    <PromotionBox
                        banner={BannerFrete990}
                        title="Frete R$9,90 para todo o Brasil"
                        subtitle={`Promo frete R$9,90 para todo o Brasil até 0,5kg`}
                        dateStart={<Date title="Início" date="12/08/21" icon={<Icon icon={faCalendarStar} />} />}
                        dateEnd={<Date title="fim" date="20/10/21" icon={<Icon icon={faCalendarTimes} />} />}
                    >
                        <div className="text">
                            <p>
                                Compre qualquer produto dentro do Scamb até 0,5kg e pague R$9,90 de frete  para todo o Brasil.
                            </p>

                            <p>
                                A promoção é válida  tanto para a área "pessoas que circulam" como para a área "marcas que circulam".
                            </p>

                            <p>
                                Condição válida para produtos vendidos e enviados pelo Scamb com a opção "correios".
                            </p>

                            <p>
                                O presente Regulamento poderá ser alterado e/ou a Promoção suspensa e/ou cancelada a qualquer momento, sem prévio aviso.
                            </p>

                            <p>
                                <small>Promoção válida até às 23h59 do dia 30.08.21</small>
                            </p>
                        </div>
                    </PromotionBox>

                </div>
            )}
        </section>
    )
}

const mapStateToProps = (state) => ({
    site_properties: state.websiteReducer.site_properties,
    website: state.websiteReducer.website
})

export default connect(mapStateToProps, {})(Promotions);
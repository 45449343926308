import React from 'react';
import { Grid } from '@material-ui/core';
import {Link} from "react-router-dom";

function Knowmore({ title, text, link, children  }) {
  return (
    <div className="_knowmore-container">

          <div className="wrapper">
            <h3 className="_knowmore-title">{title}</h3>
            <p className="_knowmore-text">{children}</p>
            <button type="button" className="btn btn-blue">
              <a href={link ? link : "##!"}>
                Saiba Mais!
              </a>
            </button>
          </div>
    </div>
  )
}

export default Knowmore;